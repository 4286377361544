import React, {useEffect, useRef, useState} from 'react';
import {connect, useDispatch} from "react-redux";
import Loader from "./layout/loading/loader";
import './UsersNewOrEdit.css'
import {useParams} from "react-router-dom";
import {campaignsActions} from "../../modules/campaigns/campaigns.actions";
import {TravelExplore} from "@mui/icons-material";
import PageTitle from "./layout/PageTitle";
import {
    Avatar,
    Box, Button,
    Card,
    Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import {helper} from "../../utils/helper";
import * as jschardet from "jschardet";
import CSVHandler from "../../utils/CSVHandler";
import Constants from "../../utils/constants";
import {styled} from '@mui/material/styles';
import {patrimoinesActions} from "../../modules/patrimoines/patrimoines.actions";


function CampaignUpdate({...props}) {
    const dispatch = useDispatch();
    const params = useParams();
    const [campaignId, setCampaignId] = useState(null);
    const inputFileRef = useRef(null);
    const [newNombrePoints, setNewNombrePoints] = useState(0)
    const [newNombreConteneurs, setNewNombreConteneurs] = useState(0)
    const [errorMessages, setErrorMessages] = useState([])
    const [missingContainers, setMissingContainers] = useState([])
    const [extraContainers, setExtraContainers] = useState([])
    const [newConteneurs, setNewConteneurs] = useState([])
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setLoading(false);
    };


    useEffect(() => {
        if (params.hasOwnProperty("id")) {
            setCampaignId(params.id);
        } else {
            setCampaignId(null);
        }
    }, [params]);

    useEffect(() => {
        if (campaignId && (props.campaign?.id !== parseInt(campaignId)) && !props.loading && !props.error) {
            dispatch(campaignsActions.fetchCampaign(campaignId));
        }
    }, [props, dispatch, campaignId]);

    useEffect(() => {
        if (props.campaign && (!props.patrimoine || (props.patrimoine?.id !== parseInt(props.patrimoine?.id))) && !props.loading && !props.error) {
            let patrimoineId = props.campaign.patrimoine["@id"].split("/").pop();
            dispatch(patrimoinesActions.fetchPatrimoine(patrimoineId));
        }
    }, [props, dispatch, campaignId]);


    if (props.loading) {
        return <Loader/>;
    }

    const getColorForTypeFlux = (campaign) => {
        if (campaign && campaign.conteneurs?.length > 0) {
            let firstConteneur = campaign.conteneurs[0];
            return helper.getColorForDechetType(firstConteneur.dechetType)
        }

        return {backgroundColor: "#4b4b4b", color: "white"};
    }

    const getNombrePAV = (campaign) => {
        return (props.campaign?.conteneurs?.length ?? "0") + " points à laver";
    }

    const getNombreConteneurs = (campaign) => {
        let quantite = 0;
        campaign?.conteneurs?.map((c) => quantite += c.quantite);
        return quantite + " conteneurs";
    }

    const getOperateur = (campaign) => {
        if (campaign?.operateurs?.length > 0) {
            let o = campaign.operateurs[0];
            return o.libelle
        }
    }

    const getContainersInInitialOrder = (campaign) => {
        if (campaign && campaign.conteneurs?.length > 0) {
            return campaign.conteneurs.sort((a, b) => a.ordreCsv - b.ordreCsv)
        }
        return []
    }

    const formatConteneurData = (conteneur) => {
        return conteneur.ordreCsv + " : " + conteneur.nomenclature + " - " + conteneur.adresse
    }

    const publishErrorMessage = (msg) => {
        setErrorMessages([...errorMessages, msg])
    }

    const handleResetFileInput = () => {
        if (inputFileRef.current) {
            inputFileRef.current.value = null;
        }

        setErrorMessages([])
        setMissingContainers([])
        setExtraContainers([])
        setNewConteneurs([])
        setNewNombrePoints(0)
    };

    const handleCSVUpload = async (csv) => {
        if (!csv) {
            // Fichier Non Présent faire alerte.
            return;
        }
        let detectedEncoding = 'UTF-8';  // Fallback to UTF-8

        // Utilisation de readAsBinaryString pour la détection de l'encodage
        await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (e) {
                const binaryString = e.target.result;
                const detectResult = jschardet.detect(binaryString);
                detectedEncoding = detectResult.encoding || 'UTF-8';  // Fallback to UTF-8
                resolve();
            };
            reader.onerror = reject;
            reader.readAsBinaryString(csv.slice(0, 1024));  // Lire seulement les premiers octets
        });

        try {
            let content;

            await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = function (e) {
                    const arrayBuffer = e.target.result;
                    const textDecoder = new TextDecoder(detectedEncoding);
                    content = textDecoder.decode(new Uint8Array(arrayBuffer));
                    resolve();
                };
                reader.onerror = reject;
                reader.readAsArrayBuffer(csv);
            });


            const csvLines = content.split('\n');
            const headers = csvLines[0].split(';');
            const headersValidation = CSVHandler.checkHeaders(headers, Constants.EXPECTED_UPDATE_TOURNEE_HEADER);

            if (headersValidation !== true) {
                if (headersValidation.missingHeaders.length > 0) {
                    const missingHeadersMessage = `En-tête(s) manquant(s) dans le fichier : ${headersValidation.missingHeaders.join(', ')}`;
                    publishErrorMessage(missingHeadersMessage)
                }
                return;
            }

            const conteneursMap = {};

            csvLines.slice(1, csvLines.length - 1).forEach((line, index) => {
                const [nomenclature, adresse] = line.split(';');
                const cleanNomenclature = nomenclature.trim().replace(/[DdGg]$/, ''); // Supprimez le suffixe G ou D


                if (!conteneursMap[cleanNomenclature]) {
                    conteneursMap[cleanNomenclature] = {
                        nomenclature: cleanNomenclature,
                        adresse: adresse === "\r" ? "" : adresse,
                        ordreCsv: index + 1
                    };
                }
            });

            const newConteneurs = Object.values(conteneursMap);

            const conteneurs = [...props.campaign.conteneurs]; // on crée un copie pour pas toucher à la campagne original

            let nomenclaturesOriginales = conteneurs.map(c => c.nomenclature);
            let nomenclaturesNew = newConteneurs.map(c => c.nomenclature);
            let missingNomenclature = nomenclaturesOriginales.filter(n => !nomenclaturesNew.includes(n));
            let extraNomenclature = nomenclaturesNew.filter(n => !nomenclaturesOriginales.includes(n));

            setMissingContainers(conteneurs.filter(c => missingNomenclature.includes(c.nomenclature)))
            setExtraContainers(newConteneurs.filter(c => extraNomenclature.includes(c.nomenclature)));
            setNewNombrePoints(newConteneurs.length)
            let newQuantity = 0
            newConteneurs.map((c) => {
                let conteneur = conteneurs.find((c1) => c1.nomenclature === c.nomenclature);
                if (conteneur) {
                    newQuantity += conteneur.quantite
                }
            })
            setNewNombreConteneurs(newQuantity)
            setNewConteneurs(newConteneurs)
        } catch (err) {
            console.error('An error occurred:', err);
            // Handle the error as needed.
        }
    }

    const getConteneurOrdreStatusColor = (conteneur) => {
        if (missingContainers.includes(conteneur)) {
            return {
                backgroundColor: "#B22222",
                color: "#FFF"
            }
        }
        const conteneurDansNouvelleTournee = newConteneurs.find(c => c.nomenclature === conteneur.nomenclature);
        if (conteneurDansNouvelleTournee && conteneurDansNouvelleTournee.ordreCsv !== conteneur.ordreCsv) {
            return {
                backgroundColor: "var(--thm-black)",
                color: "#FFF"
            }
        }
    }

    const getOrdreChangeLabel = (conteneur) => {
        const conteneurDansNouvelleTournee = newConteneurs.find(c => c.nomenclature === conteneur.nomenclature);
        if (conteneurDansNouvelleTournee && conteneurDansNouvelleTournee.ordreCsv !== conteneur.ordreCsv) {
            return (
                <Item bgcolor={"var(--thm-black-light3)"} color={"#FFF"}>
                    {conteneur.ordreCsv + " => " + conteneurDansNouvelleTournee.ordreCsv}
                </Item>
            )
        }
    }

    const getAdresseChangeLabel = (conteneur) => {
        debugger
        if (conteneur && conteneur.adresse && conteneur.adresse !== "") {
            const conteneurDansAncienneTournee = props.campaign?.conteneurs.find(c => c.nomenclature === conteneur.nomenclature);
            if (conteneurDansAncienneTournee && conteneurDansAncienneTournee.adresse.trim() !== conteneur.adresse.trim()) {
                return (
                    <Item bgcolor={"var(--thm-black-light3)"} color={"#FFF"}>
                        {conteneurDansAncienneTournee.adresse + " => " + conteneur.adresse}
                    </Item>
                )
            }
        }

    }

    const getNewConteneurOrdreStatusColor = (conteneur) => {
        if (extraContainers.includes(conteneur)) {
            return {
                backgroundColor: "var(--thm-base)",
                color: "#FFF"
            }
        }
    }

    const getMissingConteneursLabel = () => {
        let label = "Conteneur(s) supprimé(s) : "
        if (missingContainers.length > 0) {
            label += missingContainers.map((c) => {
                return c.nomenclature
            }).join(" - ");
        } else {
            label += " Aucun"
        }

        return label;
    }

    const getExtraConteneursLabel = () => {
        let label = "Conteneur(s) ajouté(s) : "
        if (extraContainers.length > 0) {
            label += extraContainers.map((c) => {
                return c.nomenclature
            }).join(" - ");
        } else {
            label += " Aucun"
        }

        return label;
    }

    const getNombreChangementOrdreLabel = () => {
        let change = 0;
        newConteneurs.forEach((c) => {
            let initialConteneur = props.campaign?.conteneurs?.find((e) => e.nomenclature == c.nomenclature)
            if (initialConteneur && initialConteneur.ordreCsv !== c.ordreCsv) {
                ++change
            }
        })

        return "Nombre de changement d'ordre : " + change;
    }
    const getNombreChangementAdresseLabel = () => {
        let change = 0;
        newConteneurs.forEach((c) => {
            let initialConteneur = props.campaign?.conteneurs?.find((e) => e.nomenclature == c.nomenclature)
            if (initialConteneur && initialConteneur.adresse.trim() !== c.adresse.trim()) {
                ++change
            }
        })

        return "Nombre de changement d'adresse : " + change;
    }

    const startUpdate = async () => {
        setLoading(true);
        // On peut tenter d'update directement le patrimoine avec les conteneurs dans le nouvel ordre
        if (props.patrimoine && props.patrimoine?.conteneurs.length > 0) {
            const conteneursPatrimoine = [...props.patrimoine.conteneurs];
            for (let i = conteneursPatrimoine.length - 1; i >= 0; i--) {
                const conteneurPatrimoine = conteneursPatrimoine[i];
                const conteneurCorrespondant = newConteneurs.find((c) => c.nomenclature === conteneurPatrimoine.nomenclature);
                if (conteneurCorrespondant) {
                    if (conteneurCorrespondant.adresse !== "" && conteneurPatrimoine.adresse.trim() !== conteneurCorrespondant.adresse.trim()) {
                        //Changement d'adresse donc calcul lat/lng
                        let fullAdd = conteneurPatrimoine.adresse + " " + conteneurPatrimoine.complementAdresse + " " + conteneurPatrimoine.commune;
                        const geometry = await helper.geocodeAddress(fullAdd);
                        console.log("conteneur " + conteneurPatrimoine.nomenclature + " geocodée : " + JSON.stringify(geometry));
                        conteneurPatrimoine.latitude = JSON.stringify(geometry.latitude);
                        conteneurPatrimoine.longitude = JSON.stringify(geometry.longitude);
                        if (conteneurCorrespondant.adresse !== "") {
                            conteneurPatrimoine.adresse = conteneurCorrespondant.adresse;
                        }
                    }
                    if (conteneurPatrimoine.ordreCsv !== conteneurCorrespondant.ordreCsv) {
                        conteneurPatrimoine.ordreCsv = conteneurCorrespondant.ordreCsv;
                    }
                } else {
                    // Supprimer le conteneur du plan de tournée
                    conteneursPatrimoine.splice(i, 1);
                }
            }


            props.patrimoine.conteneurs = conteneursPatrimoine;
            dispatch(patrimoinesActions.updatePatrimoine(props.patrimoine, props.patrimoine.id, () => {
                handleClose()
                setTimeout(() => {
                    window.location.reload()
                }, 250)
            }));

        } else {
            setLoading(false)
        }
    }

    const Item = styled(Paper)(({theme, bgcolor, color}) => ({
        backgroundColor: bgcolor,
        padding: theme.spacing(.5),
        textAlign: 'center',
        color: color,
        ...theme.typography.subtitle2
    }));

    return (
        <>
            <PageTitle title={"Mettre à jour le plan de tournée"}
                       icon={<TravelExplore/>}/>

            <Paper sx={{padding: ".5rem 1rem"}}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Card variant="outlined">
                            <Box sx={{p: 2}}>
                                <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {props.campaign?.libelle}
                                    </Typography>
                                    <Typography gutterBottom variant="subtitle" component="div">
                                        Prochain lavage 20/02/2024
                                    </Typography>
                                </Stack>
                                <Typography color="text.secondary" variant="body2">
                                    par {getOperateur(props.campaign)}
                                </Typography>
                            </Box>
                            <Divider/>
                            <Box sx={{p: 2}}>
                                <Typography variant="subtitle1" mb={1}>
                                    Informations
                                </Typography>
                                <Stack direction="row" spacing={1}>
                                    <Chip sx={getColorForTypeFlux(props.campaign)} label="AB" size="small"/>
                                    <Chip label={getNombrePAV(props.campaign)} size="small"/>
                                    <Chip label={getNombreConteneurs(props.campaign)} size="small"/>
                                </Stack>
                            </Box>
                            <Divider/>
                            <Box sx={{p: 2}}>
                                <Typography gutterBottom variant="subtitle1" mb={1}>
                                    Ordre actuel
                                </Typography>
                                <Box>
                                    <List dense>
                                        {getContainersInInitialOrder(props.campaign).map((conteneur, index) => (
                                            <ListItem key={index} divider
                                                      sx={getConteneurOrdreStatusColor(conteneur)}
                                                      secondaryAction={
                                                          <Stack direction={"row"}>
                                                              {missingContainers.includes(conteneur) &&
                                                                  <Item bgcolor={"#FFF"} color={"var(--thm-red)"}>
                                                                      Supprimé
                                                                  </Item>
                                                              }
                                                              {getOrdreChangeLabel(conteneur)}
                                                          </Stack>
                                                      }>
                                                <ListItemText primary={formatConteneurData(conteneur)}/>
                                            </ListItem>
                                        ))}
                                    </List>

                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card variant="outlined">
                            <Box sx={{p: 2}}>
                                <Stack direction="row" justifyContent="space-between"
                                       alignItems="center">
                                    <Typography gutterBottom variant="h5" component="div">
                                        Importer nouvel ordre de tournée
                                    </Typography>
                                </Stack>
                                <Typography color="text.secondary" variant="body2">
                                    fichier CSV avec nomenclature et adresse dans le nouvel ordre
                                </Typography>
                            </Box>
                            <Divider/>
                            <Box sx={{p: 2}}>
                                <Typography variant="subtitle1" mb={1}>
                                    Informations
                                </Typography>
                                <Stack direction="row" spacing={1}>
                                    <Chip sx={getColorForTypeFlux(props.campaign)} label="AB"
                                          size="small"/>
                                    <Chip label={newNombrePoints + " points à laver"} size="small"/>
                                    <Chip label={newNombreConteneurs + " conteneurs"} size="small"/>
                                </Stack>
                            </Box>
                            <Divider/>
                            <Box sx={{p: 2}}>
                                <Stack direction="row" justifyContent="space-between" alignItems="top">
                                    <Box>
                                        {errorMessages.length > 0 && errorMessages.map((msg) => (
                                            <Typography gutterBottom variant={"subtitle1"} mb={1}
                                                        color={"error"}>{msg}</Typography>
                                        ))}
                                        {errorMessages.length === 0 &&
                                            <Typography gutterBottom variant="subtitle1" mb={1}>
                                                Nouvel ordre
                                            </Typography>
                                        }
                                    </Box>

                                    <form>
                                        <input
                                            id="csv-input"
                                            type="file"
                                            accept="text/csv"
                                            ref={inputFileRef}
                                            style={{display: 'none'}}
                                            onChange={(event) => {
                                                handleCSVUpload(event.target.files[0])
                                            }}
                                        />
                                        <label htmlFor="csv-input">
                                            <Button variant="contained" component="span"
                                                    onClick={handleResetFileInput}>
                                                Importer
                                            </Button>
                                        </label>
                                    </form>
                                </Stack>
                                <Box>
                                    <List dense>
                                        {newConteneurs && newConteneurs.map((conteneur, index) => (
                                            <ListItem key={index} divider
                                                      sx={getNewConteneurOrdreStatusColor(conteneur)}
                                                      secondaryAction={
                                                          <Stack direction={"row"}>
                                                              {extraContainers.includes(conteneur) &&
                                                                  <Item bgcolor={"var(--thm-base-light)"}
                                                                        color={"var(--thm-base-accent)"}>
                                                                      Nouveau
                                                                  </Item>
                                                              }
                                                              {getAdresseChangeLabel(conteneur)}
                                                          </Stack>

                                                      }>
                                                <ListItemText primary={formatConteneurData(conteneur)}/>
                                            </ListItem>
                                        ))}
                                    </List>

                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={12} container justifyContent={"center"} pt={2}>
                        <Grid item xs={4}>
                            <Button
                                variant={"contained"}
                                fullWidth
                                onClick={handleClickOpen}
                                disabled={newConteneurs.length === 0}
                            >
                                Valider
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Mettre à jour la tournée {props.campaign?.libelle} ?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <List>
                            <ListItem>
                                <ListItemText primary={getMissingConteneursLabel()}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={getExtraConteneursLabel()}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={getNombreChangementOrdreLabel()}/>
                            </ListItem>
                            <ListItem>
                                <ListItemText primary={getNombreChangementAdresseLabel()}/>
                            </ListItem>
                        </List>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus variant={"contained"} color={"error"}
                            disabled={loading}>Annuler</Button>
                    <Button onClick={startUpdate} variant={"contained"} disabled={loading}>
                        {!loading && "Mettre à jour le plan de tournée"}
                        {loading && <CircularProgress/>}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        campaign: state.campaigns.campaign,
        patrimoine: state.patrimoines.patrimoine,
        loading: state.campaigns.loading || state.patrimoines.loading,
        error: state.campaigns.error || state.patrimoines.error,
    }
}

export default connect(mapStateToProps)(CampaignUpdate);
